@import './breakpoints.scss';
@import './colors.scss';
@import './typography.scss';
@import './elevation.scss';

:root {
  /* Colors */
  --darkRed: #81211c;
  --disabled: rgba(239, 239, 239, 0.3);

  /* Positioning */
  --containerPadding: 28px;
  --headerHeight: 84px;
  --contentPadding: 24px;

  --font_size_xs: clamp(0.67rem, calc(0.7rem + -0.04vw), 0.69rem);
  --font_size_s: clamp(0.83rem, calc(0.82rem + 0.08vw), 0.89rem);
  --font_size_base: clamp(1rem, calc(0.95rem + 0.27vw), 1.19rem);
  --font_size_l: clamp(1.2rem, calc(1.09rem + 0.55vw), 1.58rem);
  --font_size_xl: clamp(1.44rem, calc(1.25rem + 0.97vw), 2.11rem);
  --font_size_xxl: clamp(1.73rem, calc(1.42rem + 1.56vw), 2.81rem);
  --font_size_xxxl: clamp(2.07rem, calc(1.6rem + 2.42vw), 3.75rem);

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --spaces-none: 0;
  --spaces-xxxs: clamp(0.25rem, calc(0.23rem + 0.09vw), 0.31rem);
  --spaces-xxs: clamp(0.5rem, calc(0.46rem + 0.18vw), 0.63rem);
  --spaces-xs: clamp(0.75rem, calc(0.71rem + 0.18vw), 0.88rem);
  --spaces-s: clamp(1rem, calc(0.95rem + 0.27vw), 1.19rem);
  --spaces-m: clamp(1.5rem, calc(1.41rem + 0.45vw), 1.81rem);
  --spaces-l: clamp(2rem, calc(1.89rem + 0.54vw), 2.38rem);
  --spaces-xl: clamp(3rem, calc(2.84rem + 0.81vw), 3.56rem);
  --spaces-xxl: clamp(4rem, calc(3.79rem + 1.08vw), 4.75rem);
  --spaces-xxxl: clamp(6rem, calc(5.68rem + 1.62vw), 7.13rem);
  --spaces-s-l: clamp(1rem, calc(0.61rem + 1.98vw), 2.38rem);
  --spaces-s-xl: clamp(1rem, calc(0.27rem + 3.7vw), 3.56rem);

  --max-width-text: calc(var(--spaces-m) * 31);
}

@font-face {
  font-family: 'OUStandard';
  src: url('../public/fonts/OUStandard.otf') format('opentype');
}

html {
  box-sizing: border-box;
  font-size: var(--baseFontSize);
  -webkit-font-smoothing: antialiased;
}

body {
  background: var(--special-white);
  font-family: var(--primaryFont);
  color: var(--malm-malm-0);
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

header {
  position: sticky;
  z-index: 7;
  top: 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

footer {
  background: var(--malm-malm-0);
  color: var(--special-white);
  font-size: var(--smallText);
  padding: 0 0 6em;
  position: relative;
}

section {
  padding: 4rem 0 4rem;
  @include smallerThan(small) {
    padding: 3.2rem 0 3.2rem;
  }
}

textarea {
  font-family: var(--primaryFont);
}

/* button {
  padding-left: 48px;
  padding-right: 48px;
  box-sizing: border-box;
} */

button.noEffects {
  &:hover {
    background: initial;
  }

  &:active {
    background: initial;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

p {
  margin: 0 0 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1.38rem;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-weight: 900;
  margin-top: 0;
}

.two-column-paragraph p {
  column-count: 2;
}

.builder-text {
  max-width: var(--max-width-text);
  width: 100%;
  display: block;
}
.builder-text h1 > span,
.builder-text > h1 {
  font-weight: 900;
  margin-top: 0;
  font-size: var(--font_size_xxl);
}
.builder-text h2 > span,
.builder-text > h2 {
  font-weight: 900;
  font-size: var(--font_size_xl);
}

.builder-text h3 > span,
.builder-text > h3 {
  font-weight: 900;
  font-size: var(--font_size_l);
}

.builder-text h4 > span,
.builder-text > h4 {
  font-weight: 900;
  font-size: var(--font_size_base);
}

.builder-text h5 > span,
.builder-text > h5 {
  font-weight: 900;
  font-size: var(--font_size_s);
}

.builder-text h6 > span,
.builder-text > h6 {
  font-weight: 900;
  font-size: var(--font_size_xs);
}

.builder-text p > span,
builder-text span {
  font-size: var(--font_size_base);
  font-family: var(--primaryFont);
}

ul {
  list-style: none;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--malm-malm-0);
  text-decoration: none;
}

input {
  font-family: var(--primaryFont);
  font-size: 16px;
  font-weight: 600;

  @include largerThan(small) {
    font-size: 14px;
  }
}

textarea {
  font-size: 16px;
  font-weight: 600;

  @include largerThan(small) {
    font-size: 14px;
  }
}

input:disabled {
  background-color: var(--disabled);
}

.grecaptcha-badge {
  z-index: 1;
}

.smallerThanSmall {
  @include largerThan(small) {
    display: none !important;
  }
}

.smallerThanMedium {
  @include largerThan(medium) {
    display: none !important;
  }
}

.smallerThanLarge {
  @include largerThan(large) {
    display: none !important;
  }
}

.smallerThanXLarge {
  @include largerThan(xlarge) {
    display: none !important;
  }
}

.largerThanSmall {
  @include smallerThan(small) {
    display: none !important;
  }
}

.largerThanMedium {
  @include smallerThan(medium) {
    display: none !important;
  }
}

.largerThanLarge {
  @include smallerThan(large) {
    display: none !important;
  }
}

.largerThanXLarge {
  @include smallerThan(xlarge) {
    display: none !important;
  }
}

@include largerThan(small) {
  :root {
    --contentPadding: 44px;
  }
}

@include largerThan(small) {
  :root {
    /* Font sizes for large screens */
    //--baseFontSize: 16px;
    //--xtraLargeText: 74px;
    /* --heading-1: 64px;
    --heading-2: 42px;
    --heading-3: 23px;
    --heading-4: 18px; */
    /* --heading-0: 56px;
    --heading-1: 42px;
    --heading-2: 30px;
    --heading-3: 23px;
    --heading-4: 18px;
    --smallText: 14px;
    --xtraSmallText: 12px; */

    --containerPadding: 38px;
    --contentPadding: 74px;
  }
}

@include largerThan(large) {
  :root {
    --contentPadding: 148px;
  }
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
/* if the only class is slick-slide it means it's not active/showing,
so we set the height: 0px; so it won't affect the layout when it's not showing */
div[class='slick-slide'] {
  height: 0px;
}

[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  //font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;
  text-align: center;

  @include smallerThan(medium) {
    bottom: -40px;
  }
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 18px;
  height: 18px;
  margin: 0 0px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background-image: unset;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  background-image: unset;
}
.slick-dots li button:before {
  font-size: 40px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 0.15;
  color: var(--malm-malm-0);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: unset;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: var(--malm-malm-0);
  background-image: unset;
}
.slick-dots li button:active {
  background-image: unset;
}
.slick-initialized .slick-slide.slick-active {
  z-index: 1;
}

// important (yes, pun intended) styling overrides for Kundo-widget
.kundo-knowledge-widget__button {
  background-color: var(--malm-malm-0) !important;
  color: var(--special-white) !important;
  font-weight: 600 !important;
  padding: 0.625em !important;
  min-width: 2.5em !important;

  &:hover {
    background-color: #3d3d3d !important;
  }

  &:active {
    color: var(--special-white) !important;
    background-color: #3d3d3d !important;
    font-weight: 600 !important;
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
