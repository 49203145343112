.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #a4a4a4;
  transition: 0.6s ease-in-out;
  border-radius: 32px;
  color: transparent;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 7px;
  bottom: 7px;
  background-color: white;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #a5c7b1;
}

input:checked + .slider:before {
  transform: translate(24px);
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
}
