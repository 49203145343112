:root {
  --special-facebook: #1877f2;
  --special-black: #000;
  --special-white: #fff;
  --special-warning: #b34141;
  --special-heather-alert: #b40b0b;
  --special-scrim-light: rgba(255, 255, 255, 0.9);
  --special-scrim-dark: rgba(60, 61, 65, 0.6);
  --special-scrim-selected: rgba(255, 255, 255, 0.6);

  --lav-lav-0: #605950;
  --lav-lav-1: #a59b8f;
  --lav-lav-2: #d3ccc4;
  --lav-lav-3: #edeae6;
  --lav-lav-4: #f9f8f6;

  --kantarell-kantarell-0: #e7c549;
  --kantarell-kantarell-1: #f3d877;
  --kantarell-kantarell-2: #fae7a2;
  --kantarell-kantarell-3: #fdf4d4;
  --kantarell-kantarell-4: #fefbf1;

  --hjortron-hjortron-0: #d79149;
  --hjortron-hjortron-1: #e2a76c;
  --hjortron-hjortron-2: #ecc296;
  --hjortron-hjortron-3: #f6e4d2;
  --hjortron-hjortron-4: #fbf4ed;

  --ljung-ljung-0: #bc6565;
  --ljung-ljung-1: #d49393;
  --ljung-ljung-2: #e1b3b3;
  --ljung-ljung-3: #f0d5d6;
  --ljung-ljung-4: #f9f1f1;

  --mossa-mossa-0: #487a5c;
  --mossa-mossa-1: #76a387;
  --mossa-mossa-2: #a5c7b1;
  --mossa-mossa-3: #cde0d5;
  --mossa-mossa-4: #edf4ef;

  --malm-malm-0: #222;
  --malm-malm-1: #565656;
  --malm-malm-4: #f9f9f9;
  --malm-malm-2: #a4a4a4;
  --malm-malm-3: #e6e6e6;

  --bl-b-r-bl-b-r-0: #5b436c;
  --bl-b-r-bl-b-r-1: #826c92;
  --bl-b-r-bl-b-r-2: #b7a9c1;
  --bl-b-r-bl-b-r-3: #d9d2de;
  --bl-b-r-bl-b-r-4: #f0edf2;

  --lv-lv-0: #34648c;
  --lv-lv-1: #6493ba;
  --lv-lv-2: #99b5cd;
  --lv-lv-3: #cddae6;
  --lv-lv-4: #eff3f8;

  --system-blue-light: #007aff;

  --backgroundColor: var(--lav-lav-4);
  --textColor: var(--malm-malm-0);
}
