@import '../../styles/breakpoints.scss';

.container {
  background: var(--lav-lav-4);
  padding: 32px 32px;
  padding-bottom: 32px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 7;

  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  @include largerThan(xlarge) {
    padding: 32px 96px;
  }

  overflow-y: auto;
  max-height: 100%;
  overscroll-behavior: contain;

  @include largerThan(small) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.container::-webkit-scrollbar {
  @include largerThan(small) {
    display: none;
  }
}

.cookieSettingsButton {
  cursor: pointer;
  display: flex;

  align-items: center;
  flex-direction: row;
  color: var(--malm-malm-0);

  background: transparent;
  border: 0;
  padding: 0;
}

.cookieIcon {
  flex-shrink: 0;
  height: 68px;
  aspect-ratio: 1;
}

.cookieSettingRow {
  display: grid;
  grid-template-columns: 1fr 80px;
  @include largerThan(medium) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  align-items: start;
  padding-top: 2rem;
}

.cookieSettingRowSwitch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.links {
  text-decoration: underline;
}

.links:hover {
  font-weight: 400;
}
