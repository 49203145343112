:root {
  --primaryFont: 'noto-sans', sans-serif;
  --secondaryFont: 'abril-fatface', serif;

  --baseFontSize: 16px;
  --heading-1: 36px;
  --heading-2: 23px;
  --heading-3: 18px;
  --heading-4: 16px;
  --smallText: 14px;
  --xtraSmallText: 12px;
}

* {
  font-family: var(--primaryFont);
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: var(--baseFontSize);
  font-weight: normal;
  line-height: 1.5;
}

p {
  margin: 0 0 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1.38rem;
  font-weight: bold;
  line-height: 1.2;
}

h1 {
  font-size: var(--heading-1);
}

h2 {
  font-size: var(--heading-2);
}

h3 {
  font-size: var(--heading-3);
}

h4 {
  font-size: var(--heading-4);
}

h5 {
  font-size: var(--heading-5);
}

@include largerThan(small) {
  :root {
    /* Media query: font sizes for large screens */
    --heading-0: 72px;
    --heading-1: 42px;
    --heading-2: 30px;
    --heading-3: 22px;
    --heading-4: 18px;
  }
}
